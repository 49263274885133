<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-text
      class="px-md-16 px-lg-16"
    >
      <v-row class="d-flex align-end mb-3">
        <v-col
          cols="12"
          md="4"
        >
          <v-dialog
            ref="dialogOpen"
            v-model="modalOpen"
            :return-value.sync="openTime"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="openTimeComputed"
                :label="t('fields.time_open')"
                :prepend-inner-icon="icons.mdiClockTimeFourOutline"
                clearable
                outlined
                dense
                readonly
                hide-details
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="modalOpen"
              v-model="openTime"
              color="secondary"
              full-width
              format="24hr"
              :readonly="option===2"
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="secondary"
                @click="modalOpen = false"
              >
                {{ t('tooltip.cancel') }}
              </v-btn>
              <v-btn
                text
                color="secondary"
                @click="$refs.dialogOpen.save(openTime)"
              >
                Ok
              </v-btn>
            </v-time-picker>
          </v-dialog>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-dialog
            ref="dialogClose"
            v-model="modalClose"
            :return-value.sync="closeTime"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="closeTimeComputed"
                :label="t('fields.time_close')"
                :prepend-inner-icon="icons.mdiClockTimeFourOutline"
                clearable
                outlined
                dense
                readonly
                hide-details
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="modalClose"
              v-model="closeTime"
              color="secondary"
              full-width
              format="24hr"
              :readonly="option===2"
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="secondary"
                @click="modalClose = false"
              >
                {{ t('tooltip.cancel') }}
              </v-btn>
              <v-btn
                text
                color="secondary"
                @click="$refs.dialogClose.save(closeTime)"
              >
                OK
              </v-btn>
            </v-time-picker>
          </v-dialog>
        </v-col>

        <v-col
          cols="12"
          md="4"
          class="text-center"
        >
          <v-btn
            @click="setDaySelected"
          >
            {{ t('fields.set_time') }}
          </v-btn>
        </v-col>
      </v-row>
      <!-- table -->
      <v-data-table
        v-model="daySelected"
        :headers="computedTableColumns"
        :items="option===1 ? scheduleInit : dataComputed.schedule"
        item-key="weekday"
        show-select
        dense
        hide-default-footer
        :loading-text="t('table.loading_text')"
        :no-data-text="t('table.no_data')"
        :header-props="{sortByText: t('table.sort_by')}"
      >
        <!-- weekday -->
        <template #[`item.weekday`]="{item}">
          {{ t(`weekdays.${item.weekday.toLowerCase()}`) }}
        </template>
        <!--open_time -->
        <template #[`item.open_time`]="{item}">
          <v-edit-dialog
            :return-value.sync="item.open_time"
            large
          >
            {{ `${item.open_time ? formatStdDate(item.open_time, 'time', configObjFacility.is12Hour, $i18n.locale) : '--:--'}` }}
            <template v-slot:input>
              <div class="mt-4 title">
                {{ t('fields.update_open_time') }}
              </div>
              <v-time-picker
                v-model="item.open_time"
                :label="t('fields.open_time')"
                format="24hr"
                color="secondary"
                :readonly="option===2"
              ></v-time-picker>
            </template>
          </v-edit-dialog>
        </template>

        <!-- close_time -->
        <template #[`item.close_time`]="{item}">
          <v-edit-dialog
            :return-value.sync="item.close_time"
            large
          >
            {{ `${item.close_time ? formatStdDate(item.close_time, 'time', configObjFacility.is12Hour, $i18n.locale) : '--:--'}` }}
            <template v-slot:input>
              <div class="mt-4 title">
                {{ t('fields.update_close_time') }}
              </div>
              <v-time-picker
                v-model="item.close_time"
                :label="t('fields.close_time')"
                format="24hr"
                color="secondary"
                :readonly="option===2"
              ></v-time-picker>
            </template>
          </v-edit-dialog>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-tooltip bottom>
            <template #activator="{ on: tooltip, attrs }">
              <v-icon
                size="20"
                class="me-2"
                color="error"
                v-bind="attrs"
                v-on="{ ...tooltip }"
                @click="onClear(item)"
              >
                {{ icons.mdiClose }}
              </v-icon>
            </template>
            <span>{{ t('fields.clear_hourly') }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card-text>

    <!-- action buttons -->
    <options-buttom-tab
      :tab-number="tab"
      :is-mode-save-edit="option !== 2"
      :info-required="true"
      :no-cancel="option === 3"
      :no-arrow="true"
      @onCancel="onCancelAction"
      @onSave="onSaveAction"
    >
    </options-buttom-tab>
  </v-card>
</template>

<script>
import { ref, computed, getCurrentInstance } from '@vue/composition-api'
import { required } from '@core/utils/validation'
import { mdiCalendar, mdiClockTimeFourOutline, mdiClose } from '@mdi/js'
import { formatStdDate } from '@core/utils'
import { info } from '@core/utils/toasted'
import { useUtils } from '@core/libs/i18n'
import OptionsButtomTab from '@/views/components/options-buttom-tab/OptionsButtomTab.vue'
import useSelectOptions from '@core/utils/useSelectOptions'

export default {
  components: {
    OptionsButtomTab,
  },
  props: {
    option: {
      type: Number,
      default: 0,
    },
    tab: {
      type: Number,
      default: 0,
    },
    dataParams: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy
    const { t } = useUtils()
    const { configObjFacility } = useSelectOptions()

    const form = ref(null)
    const openTime = ref(null)
    const closeTime = ref(null)
    const openTimeComputed = computed(() => (openTime.value ? formatStdDate(openTime.value, 'time', configObjFacility.value.is12Hour, vm.$i18n.locale) : ''))
    const closeTimeComputed = computed(() => (closeTime.value ? formatStdDate(closeTime.value, 'time', configObjFacility.value.is12Hour, vm.$i18n.locale) : ''))

    const modalOpen = ref(false)
    const modalClose = ref(false)

    const daySelected = ref([])

    const tableColumns = ref([
      { text: 'WEEKDAY', value: 'weekday', sortable: false },
      { text: 'OPEN', value: 'open_time', sortable: false },
      { text: 'CLOSE', value: 'close_time', sortable: false },
      {
        text: 'ACTIONS',
        value: 'actions',
        align: 'center',
        sortable: false,
      },
    ])

    const scheduleInit = ref([
      {
        weekday: 'Monday',
        open_time: null,
        close_time: null,
      },
      {
        weekday: 'Tuesday',
        open_time: null,
        close_time: null,
      },
      {
        weekday: 'Wednesday',
        open_time: null,
        close_time: null,
      },
      {
        weekday: 'Thursday',
        open_time: null,
        close_time: null,
      },
      {
        weekday: 'Friday',
        open_time: null,
        close_time: null,
      },
      {
        weekday: 'Saturday',
        open_time: null,
        close_time: null,
      },
      {
        weekday: 'Sunday',
        open_time: null,
        close_time: null,
      },
    ])

    const dataComputed = computed(() => props.dataParams)
    const scheduleComputed = computed(() => (props.dataParams ? props.dataParams.schedule : scheduleInit))
    const computedTableColumns = computed(() => [
      { text: t('fields.weekday').toUpperCase(), value: 'weekday', sortable: false },
      { text: t('fields.open').toUpperCase(), value: 'open_time', sortable: false },
      { text: t('fields.close').toUpperCase(), value: 'close_time', sortable: false },
      {
        text: t('tooltip.actions').toUpperCase(),
        value: 'actions',
        align: 'center',
        sortable: false,
      },
    ])

    const setDaySelected = () => {
      if ((openTime.value || closeTime.value) && daySelected.value.length) {
        if (props.option === 1) {
          scheduleInit.value.forEach(e => {
            if (daySelected.value.includes(e)) {
              e.open_time = openTime.value
              e.close_time = closeTime.value
            }
          })
        } else {
          dataComputed.value.schedule.forEach(e => {
            if (daySelected.value.includes(e)) {
              e.open_time = openTime.value
              e.close_time = closeTime.value
            }
          })
        }
      } else info('Seleccione el dia del horario!')
    }

    const onCancelAction = () => {
      form.value.reset()
      emit('cancelAction')
    }

    const onClear = item => {
      /* eslint-disable no-param-reassign */
      item.open_time = null
      item.close_time = null
    }

    const validate = arraySchedule => arraySchedule.some(e => e.open_time !== null || e.close_time !== null)

    const onSaveAction = () => {
      if (props.option === 1 ? validate(scheduleInit.value) : validate(dataComputed.value.schedule)) {
        if (props.option === 1) dataComputed.value.schedule = scheduleInit.value

        emit('save', dataComputed.value)
      } else {
        info('Seleccione el horario!')
      }
    }

    return {
      // Data
      form,
      tableColumns,
      scheduleInit,
      openTime,
      closeTime,
      modalOpen,
      modalClose,
      daySelected,

      // Computed
      dataComputed,
      scheduleComputed,
      computedTableColumns,
      openTimeComputed,
      closeTimeComputed,
      configObjFacility,

      // Validations
      required,

      // Methods
      onCancelAction,
      onSaveAction,
      setDaySelected,
      onClear,
      formatStdDate,

      // i18n
      t,

      // Icons
      icons: {
        mdiCalendar,
        mdiClockTimeFourOutline,
        mdiClose,
      },
    }
  },
}
</script>
